import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Entry.scss';

const Entry = props => {
    const {children} = props;
    return (
        <section {...applyClassName('Entry', props)}>
            {children}
        </section>
    );
};

export default Entry;