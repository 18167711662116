import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Button.scss';

const Button = props => {
    const {children, type = 'button', ...restProps} = props;
    return (
        <button {...restProps} type={type} {...applyClassName('Button button', props)}>{children}</button>
    );
};

export default Button;