import React from 'react';

import setISOWeek from 'date-fns/setISOWeek';
import setISOWeekYear from 'date-fns/setISOWeekYear';

import {
    dateFromParams, dateChanged, parseDate, weekChanged, yearChanged, paramsFromDate
} from '../../utils/date';
import {useLocationParams, useRouter} from '../Router/RouterProvider';

import isValid from "date-fns/isValid";
import {useChangeEffect} from '../../hooks/ChangeEffect';

const initialState = {
    date: new Date()
};

const DateProvider = props => {
    const {children} = props;

    const {go} = useRouter();
    const params = useLocationParams();

    const [state, dispatch] = React.useReducer(reducer, {}, () => {
        return {...initialState, date: dateFromParams(params, initialState.date)};
    });

    const setWeek = React.useCallback(week => dispatch({type: 'setWeek', payload: week}), []);
    useChangeEffect(params.week, weekChanged, setWeek);

    const setYear = React.useCallback(year => dispatch({type: 'setYear', payload: year}), []);
    useChangeEffect(params.year, yearChanged, setYear);

    const goRoute = React.useCallback(date => {
        go('', paramsFromDate(date), {merge: true});
    }, [go]);
    useChangeEffect(state.date, dateChanged, goRoute);

    return (
        <DateContext.Provider value={[state.date, dispatch]}>
            {children}
        </DateContext.Provider>
    );
};

export default DateProvider;

const DateContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'setDate':
            return setDate(state, action.payload);
        case 'setWeek':
            return setWeek(state, action.payload);
        case 'setYear':
            return setYear(state, action.payload);
        default:
            return state;
    }
};

export const useDate = () => React.useContext(DateContext);

const setDate = (state, date) => applyDate(state, parseDate(date));
const setWeek = (state, week) => applyDate(state, setISOWeek(state.date, week));
const setYear = (state, year) => applyDate(state, setISOWeekYear(state.date, year));

const applyDate = (state, date) => (isValid(date) && dateChanged(state.date, date)) ? {...state, date} : state;