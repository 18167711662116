import React from 'react';
import Table from '../../../../components/Table/Table';

import _parseISO from 'date-fns/parseISO';
import _isSameDay from 'date-fns/isSameDay';
import {formatDate, getWeekDays} from '../../../../utils/date';
import {useDate} from '../../../../components/Date/DateProvider';
import {useBooking} from '../../../../components/Booking/BookingProvider';
import Meeting from './Meeting';

const Timetable = props => {

    const [date] = useDate();
    const [booking] = useBooking();
    const {meetings = []} = booking || {};

    const [days, setDays] = React.useState([]);
    React.useEffect(() => {
        setDays(getWeekDays(date));
    }, [date]);

    const [payload, setPayload] = React.useState();
    React.useEffect(() => {
        const today = new Date();
        const p = days.map(day => {
            const dailyData = meetings.filter(entry => _isSameDay(_parseISO(entry.starttime), day));
            return {
                head: <Head day={day}/>,
                data: <Data data={dailyData}/>,
                active: _isSameDay(day, today)
            };
        });
        setPayload(p);
    }, [days, meetings]);

    return <Table payload={payload}/>;
};

export default Timetable;

const Head = props => {
    const {day} = props;
    return (
        <>
            <h6>{formatDate(day, 'iiii')}</h6>
            <p>{formatDate(day, 'do')}</p>
        </>
    );
};

const Data = props => {
    const {data} = props;
    return (
        <>
            {Array.isArray(data) && data.map((entry, index) => <Meeting key={index} {...entry}/>)}
        </>
    );
};
