import React from 'react';

import {useDate} from '../../../components/Date/DateProvider';
import isSameYear from "date-fns/isSameYear";
import startOfISOWeek from "date-fns/startOfISOWeek";
import endOfISOWeek from "date-fns/endOfISOWeek";
import DateRange from '../../../components/Date/DateRange';

const Title = props => {
    const [date] = useDate();
    const start = startOfISOWeek(date);
    const end = endOfISOWeek(date);
    return (
        <h4 className={'color-secondary'}>
            <DateRange
                start={[start, isSameYear(start, end) ? 'do' : 'PPP']}
                end={[end, 'PPP']}
            />
        </h4>
    );
};

export default Title;