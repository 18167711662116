import {flattenDeep} from "../utils/data";
import {pathFromDirectories, pathFromLocation} from "../components/Router/utils/path";

export const HOST_URL = 'https://api.smartmeeting.online/';
export const ROOM_PATH = 'api/room/{room}/booking';
export const GUESTS_PATH = 'api/room/{room}/guests';

export const bookingPath = (path, params) => {
    const relativePath = pathFromLocation({path: pathFromDirectories(...flattenDeep([ROOM_PATH, path])), params});
    return pathFromDirectories(...flattenDeep([HOST_URL, relativePath]));
};

export const guestsPath = (path, params) => {
    const relativePath = pathFromLocation({path: pathFromDirectories(...flattenDeep([GUESTS_PATH, path])), params});
    return pathFromDirectories(...flattenDeep([HOST_URL, relativePath]));
};