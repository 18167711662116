import React from 'react';
import DateRange from '../../../../components/Date/DateRange';
import {applyClassName} from '../../../../utils/properties';

import './Room.scss';
import {useCurrentMeeting, useNextMeeting, usePrevMeeting} from '../../hooks/Meetings';
import Entry from '../../../../components/Entry/Entry';
import GridFrame from '../../../../components/Grid/GridFrame';
import {Block, Centered, Shrink} from '../../../../components/Grid/Cell';

const Room = props => {

    const {meetings} = props;

    const meeting = useCurrentMeeting(meetings);

    const prevMeeting = usePrevMeeting(meetings);
    const nextMeeting = useNextMeeting(meetings);

    return (
        <GridFrame {...applyClassName('Room', props)} level={'nested'} orientation={'vertical'}>
            <PrevNext className={'prev'} meeting={prevMeeting}/>
            <Block>
                <Centered>
                    <Current meeting={meeting}/>
                </Centered>
            </Block>
            <PrevNext className={'next'} meeting={nextMeeting}/>
        </GridFrame>
    );
};

export default Room;

const Current = props => {
    const {meeting} = props;
    if(!meeting) {
        return (
            <div className={'Current'}>
                <Entry>
                    <h1>Raum ist frei</h1>
                </Entry>
            </div>
        );
    }
    const {starttime, endtime, title, attendees} = meeting || {};
    return (
        <div className={'Current'}>
            <Entry>
                <h1>{title}</h1>
                <h2><DateRange start={[starttime, 'HH:mm']} end={[endtime, 'HH:mm']} separator={<>&nbsp;- </>} /></h2>
                <Attendees attendees={attendees}/>
            </Entry>
        </div>
    );
};

const Attendees = props => {
    const {attendees} = props;
    if(!Array.isArray(attendees) || attendees.length <= 0) return null;
    return (
        <>
            <ul className={'no-bullet'}>
                {attendees.map((attendee, index) => <li className={'h4'} key={index}>{attendee}</li>)}
            </ul>
        </>
    );
};

const PrevNext = props => {
    const {meeting} = props;
    if(!meeting) return null;
    const {starttime, endtime, title} = meeting || {};
    return (
        <Shrink {...applyClassName('PrevNext', props)}>
            <Entry>
                <h5>{title}</h5>
                <h6>(<DateRange start={[starttime, 'HH:mm']} end={[endtime, 'HH:mm']} separator={<>&nbsp;- </>} />)</h6>
            </Entry>
        </Shrink>
    );
};