import React from 'react';

import {applyClassName} from '../../utils/properties';

import logo_claim from '../../images/sm-logo-claim.svg';

import './Logo.scss';

const Logo = props => {
    const {before, after} = props;
    return (
        <div {...applyClassName('Logo', props)}>
            {before}
            <img className={'sm-logo-claim'} src={logo_claim} alt={'SmartMeeting'}/>
            {after}
        </div>
    );
};

export default Logo;