import React from 'react';
import {applyClassName} from '../../utils/properties';
import TableRow from './TableRow';
import TableCell from './TableCell';

import './Table.scss';
import {useOrientation} from '../../hooks/Orientation';

const Table = props => {
    const [orientation] = useOrientation(props);
    if (orientation === 'vertical') return <VerticalTable {...props}/>;
    return <HorizontalTable {...props}/>;
};

export default Table;

export const VerticalTable = props => {
    const {payload = []} = props;
    return (
        <table {...applyClassName(['Table', 'vertical'], props)}>
            <tbody>
            {payload.map((entry, index) => (
                <TableRow key={index}>
                    {entry.head && <TableCell {...applyClassName(entry.active && 'active')} type={'head'}>{entry.head}</TableCell>}
                    <TableCell {...applyClassName(entry.active && 'active')}>{entry.data}</TableCell>
                </TableRow>
            ))}
            </tbody>
        </table>
    );
};

export const HorizontalTable = props => {
    const {payload = []} = props;
    return (
        <table {...applyClassName(['Table', 'horizontal'], props)}>
            <thead>
            <TableRow>
                {payload.map((entry, index) => <TableCell type={'head'} {...applyClassName(entry.active && 'active')} key={index}>{entry.head}</TableCell>)}
            </TableRow>
            </thead>
            <tbody>
            <TableRow>
                {payload.map((entry, index) => <TableCell {...applyClassName(entry.active && 'active')} key={index}>{entry.data}</TableCell>)}
            </TableRow>
            </tbody>
        </table>
    );
};