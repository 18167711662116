
import {isEmpty} from '../../../utils/data';
import {pathFromLocation} from './path';

export const urlFromPath = (path) => {
    return path ? '/#/' + path : '';
};

export const urlFromLocation = location => {
    return urlFromPath(pathFromLocation(location));
};

export const queryFromParams = (params) => {
    if(isEmpty(params)) return '';
    const str = Object.keys(params).map(key => key + '=' + params[key]);
    return str.length > 0 ? '?' + str.join('&') : '';
};
