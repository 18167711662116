import React from 'react';
import {isTrue} from '../../../utils/data';
import {useLocationParams} from '../../../components/Router/RouterProvider';
import {Shrink} from '../../../components/Grid/Cell';
import Button from '../../../components/Button/Button';

import addWeeks from "date-fns/addWeeks";
import ButtonGroup from '../../../components/Button/ButtonGroup';
import DateSelect from './DateSelect/DateSelect';
import {useDate} from '../../../components/Date/DateProvider';

const Navigation = props => {

    const [date, dateDispatch] = useDate();

    const params = useLocationParams();

    const [nav, setNav] = React.useState(false);
    React.useEffect(() => {
        setNav(isTrue(params.nav));
    }, [params.nav]);

    const prev = React.useCallback(() => {
        dateDispatch({type: 'setDate', payload: addWeeks(date, -1)});
    }, [date, dateDispatch]);

    const next = React.useCallback(() => {
        dateDispatch({type: 'setDate', payload: addWeeks(date, 1)});
    }, [date, dateDispatch]);

    if(!nav) return null;

    return (
        <>
            <Shrink>
                <DateSelect/>
            </Shrink>
            <Shrink>
                <ButtonGroup>
                    <Button onClick={prev}>
                        <span className={'arrow left'}/>
                    </Button>
                    <Button onClick={next}>
                        <span className={'arrow right'}/>
                    </Button>
                </ButtonGroup>
            </Shrink>
        </>
    );
};

export default Navigation