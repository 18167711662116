import React from 'react';
import {useMounted} from './Mounted';

export const useTimer = (callback, delay) => {

    const [iteration, setIteration] = React.useState(0);

    const callbackRef = React.useRef();
    React.useEffect(() => {
        callbackRef.current = callback;
    }, [callback]);

    const mounted = useMounted();
    const [active, setActive] = React.useState();
    React.useEffect(() => {
        if(!mounted.current) setActive(false);
    }, [mounted]);

    const start = React.useCallback(async immediate => {
        immediate && mounted.current && await Promise.resolve(callbackRef.current());
        setActive(mounted.current);
    }, [mounted]);

    const stop = React.useCallback(() => {
        setActive(false);
    }, []);

    React.useEffect(() => {
        if(!active) return;
        const tick = async () => {
            if(!active) return;
            await Promise.resolve(callbackRef.current());
            if(active && mounted.current) setIteration(i => i + 1);
        };
        const timer = window.setTimeout(tick, delay);
        return () => window.clearTimeout(timer);
    }, [active, delay, iteration, mounted]);

    React.useEffect(() => {
        start();
        return () => stop();
    }, [start, stop]);

    return [start, stop];
};
