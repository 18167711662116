import React from 'react';
import {useLocationParams} from '../Router/RouterProvider';
import {apiFetch} from '../../services/networking/Api';
import {useTimer} from '../../hooks/Timer';
import {guestsPath} from '../../config/networking';
import {random} from '../../utils/data';

const initialState = {
    name: '',
    guests: [],
    currentGuest: [null, 0],
    roomLogos: [],
    currentRoomLogo: [null, 0],
    showtime: 30000,
    loaded: false
};

const GuestsProvider = props => {
    const {children} = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const params = useLocationParams();
    const [path, setPath] = React.useState();

    React.useEffect(() => {
        const {room, showtime} = params;
        if(isFinite(Number(showtime))) dispatch({type: 'setShowtime', payload: Number(showtime) * 1000});
        setPath(guestsPath('', {room}));
    }, [params]);

    const fetcher = React.useCallback(async () => {
        if(!path) return;
        const response = await apiFetch(path)
            .catch(() => ({}));
        const {body: {name = '', guests = [], room_logos = []} = {}} = response || {};
        dispatch({type: 'setName', payload: name});

        dispatch({type: 'setGuests', payload: guests});
        dispatch({type: 'setCurrentGuest', payload: random(0, guests.length - 1)});

        dispatch({type: 'setRoomLogos', payload: room_logos});
        dispatch({type: 'setCurrentRoomLogo', payload: random(0, room_logos.length - 1)});

        dispatch({type: 'setLoaded', payload: true});
    }, [path]);

    useTimer(fetcher, state.showtime * 2);

    React.useEffect(() => {
        fetcher();
    }, [fetcher]);

    const incrementCurrent = React.useCallback(() => {
        dispatch({type: 'setCurrentGuest', payload: increment(state.guests, state.currentGuest[1])});
        dispatch({type: 'setCurrentRoomLogo', payload: increment(state.roomLogos, state.currentRoomLogo[1])});
    }, [state]);

    useTimer(incrementCurrent, state.showtime);

    return (
        <GuestsContext.Provider value={[state, dispatch]}>
            {children}
        </GuestsContext.Provider>
    );
};

const increment = (collection, index) => {
    if(!Array.isArray(collection) || collection.length <= 0) return 0;
    return (index + 1) % collection.length;
};

export default GuestsProvider;

const GuestsContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'setName':
            return {...state, name: action.payload};
        case 'setGuests':
            return {...state, guests: action.payload};
        case 'setCurrentGuest':
            return {...state, currentGuest: [state.guests[action.payload], action.payload]};
        case 'setRoomLogos':
            return {...state, roomLogos: action.payload};
        case 'setCurrentRoomLogo':
            return {...state, currentRoomLogo: [state.roomLogos[action.payload], action.payload]};
        case 'setShowtime':
            return {...state, showtime: action.payload};
        case 'setLoaded':
            return {...state, loaded: action.payload};
        default:
            return state;
    }
};

export const useGuests = () => React.useContext(GuestsContext);