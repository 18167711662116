import {findRoute} from './router';
import {isEmpty} from '../../../utils/data';
import {pathFromUrl} from './path';


export const routeFromPath = ({path = '', params = {}, routes = []}) => {
    let route = findRoute(routes, 'path', path);
    if(route && !isEmpty(route.initialPath)) route = findRoute(route.routes, 'path', route.initialPath);
    return route ? {...route, ...{params}} : findRoute(routes, 'isDefault', true, null);
};

export const routeFromUrl = ({url = window.location.hash, routes = []}) => {
    const path = pathFromUrl(url);
    return routeFromPath({path, routes});
};

export const routeFromLocation = (location, routes) => {
    const {path = '', params = {}} = location || {};
    return routeFromPath({path, params, routes});
};
