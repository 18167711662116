import React, {Component} from 'react';
import RouterProvider from './components/Router/RouterProvider';
import {routesConfig} from './config/router';
import HashRouter from './components/Router/HashRouter';
import Route from './components/Router/Route';

class App extends Component {
    render() {
        return (
            <div className={'App'}>
                <RouterProvider config={routesConfig}>
                    <HashRouter>
                        <Route/>
                    </HashRouter>
                </RouterProvider>
            </div>
        );
    }
}

export default App;
