import React from 'react';
import {useLocationParams} from '../components/Router/RouterProvider';

export const useOrientation = (props = {}) => {

    const params = useLocationParams();

    const [orientation, setOrientation] = React.useState(props.orientation || params.orientation || orientationFromWindow());

    React.useEffect(() => {
        const handleResize = () => setOrientation(props.orientation || params.orientation || orientationFromWindow());
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [props.orientation, params.orientation]);

    return [orientation, setOrientation];
};

const orientationFromWindow = () =>  window.innerHeight > window.innerWidth ? 'vertical' : 'horizontal';