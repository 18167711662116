import React from 'react';
import {applyClassName} from '../../utils/properties';
import Image from './Image';

import './Avatar.scss';

const Avatar = props => {
    const {className, ...restProps} = props;
    return (
        <Image {...applyClassName('Avatar', props)} {...restProps}/>
    )
};

export default Avatar;