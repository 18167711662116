import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Header.scss';

const Header = props => {
    const {children} = props;
    return (
        <div {...applyClassName('Header', props)}>
            {children}
        </div>
    );
};

export default Header;