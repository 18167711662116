import React from 'react';
import {applyClassName} from '../../utils/properties';
import {cloneElement} from '../../utils/component';

import './InputGroup.scss';

const InputGroup = props => {
    const {label, field, button} = props;
    return (
        <div {...applyClassName('InputGroup input-group', props)}>
            {label && <span className={'input-group-label'}>{label}</span>}
            {field && cloneElement(field, {className: 'input-group-field'})}
            {button && <div className={'input-group-button'}>{button}</div>}
        </div>
    );
};

export default InputGroup;