import {useLocationParams} from '../../../components/Router/RouterProvider';
import {isTrue} from '../../../utils/data';

export const useCurrentMeeting = meetings => {
    if(!Array.isArray(meetings)) return;
    return meetings.find(m => m.current);
};

export const usePrevMeeting = meetings => {
    const params = useLocationParams();
    if(!isTrue(params.prev) || !Array.isArray(meetings)) return;
    return meetings.find(m => m.prev);
};

export const useNextMeeting = meetings => {
    const params = useLocationParams();
    if(!isTrue(params.next) || !Array.isArray(meetings)) return;
    return meetings.find(m => m.next);
};
