import React from 'react';

import {useBooking} from '../../../../components/Booking/BookingProvider';
import Room from './Room';
import {Block, Shrink} from '../../../../components/Grid/Cell';
import GridFrame from '../../../../components/Grid/GridFrame';

import './View.scss';
import {useOrientation} from '../../../../hooks/Orientation';
import {applyClassName} from '../../../../utils/properties';
import {useView} from '../../hooks/View';
import List from './List';
import {join} from '../../../../utils/data';

const View = props => {
    const view = useView();
    const [booking] = useBooking();
    const {meetings, loaded} = booking;

    if(!loaded) return null;

    const className = applyClassName('View', props);

    if(view === 'room' || (Array.isArray(meetings) && meetings.length <= 0)) return <Room {...className} meetings={meetings}/>;

    if(view === 'list') return <List {...className} meetings={meetings}/>;

    const viewConfig = (view === 'list-room' ? view : 'room-list').split('-');
    return <Grid {...className} meetings={meetings} view={viewConfig}/>;
};

export default View;

const Grid = props => {
    const [orientation] = useOrientation();
    const {view, meetings} = props;

    const [components, setComponents] = React.useState(getGridComponents(meetings));
    React.useEffect(() => { setComponents(getGridComponents(meetings)); }, [meetings]);

    return (
        <GridFrame {...applyClassName(join(view, '-'), props)} level={'nested'} orientation={orientation}>
            {components[view[0]]}
            {components[view[1]]}
        </GridFrame>
    );
};

const getGridComponents = meetings => {
    return {
        list: (
            <Block className={'list'}>
                <List meetings={meetings}/>
            </Block>
        ),
        room: (
            <Shrink className={'room'}>
                <Room meetings={meetings}/>
            </Shrink>
        )
    };
};