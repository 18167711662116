import React from 'react';
import {useGuests} from '../../../components/Guests/GuestsProvider';
import {isEmpty} from '../../../utils/data';
import {applyClassName} from '../../../utils/properties';

import './Welcome.scss';
import Image from '../../../components/Image/Image';
import Avatar from '../../../components/Image/Avatar';
import {formatDate} from '../../../utils/date';

const Welcome = props => {
    const [guests] = useGuests();
    const {currentGuest, currentRoomLogo, loaded} = guests;
    const [guest] = currentGuest || [];
    const [roomLogo] = currentRoomLogo || [];

    if(!loaded) return null;

    if(isEmpty(guest)) {
        return (
            <div {...applyClassName('Welcome', props)}>
                <Image background={true} className={'room-logo'} src={roomLogo} />
            </div>
        );
    }

    const {guest_name, guest_picture, salutation_message, room_logo, meeting_title, meeting_start} = guest;

    return (
        <div {...applyClassName('Welcome', props)}>
            <Image background={true} className={'room-logo'} src={room_logo} />
            <div>
                <Paragraph>Herzlich Willkommen{guest_name && ','}</Paragraph>
                {guest_name && <Heading>{guest_name}</Heading>}
                <Avatar className={'guest-picture'} src={guest_picture}/>
                {meeting_title && (
                    <>
                        <Paragraph>zum Meeting</Paragraph>
                        <Heading>{meeting_title}</Heading>
                    </>
                )}
                <Heading>{formatDate(meeting_start, 'do MMMM yyyy, p')} Uhr</Heading>
            </div>
            <Paragraph wrapped={true}>{salutation_message}</Paragraph>
        </div>
    );
};

export default Welcome;

const Heading = props => {
    const {children} = props;
    if(!children || React.Children.count(children) <= 0) return null;
    return <p {...applyClassName(['Heading', 'h3'], props)}>{children}</p>;
};

const Paragraph = props => {
    const {children, wrapped} = props;
    if(!children || React.Children.count(children) <= 0) return null;
    const p = <p {...applyClassName(['Paragraph', 'h4'], props)}>{children}</p>;
    return wrapped ? <div>{p}</div> : p;
};