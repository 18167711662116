import {pathMatch} from './path';

export const findRoute = (routes, key, value, comparator = pathMatch) => {
    return routes
        .map(route => {
            const {routes = []} = route;
            if(routes.length > 0) {
                const r = findRoute(routes, key, value, comparator);
                if(r) return r;
            }
            if(!route.hasOwnProperty(key)) return null;
            if(comparator instanceof Function) return comparator(route[key], value) ? route : null;
            return route[key] === value ? route : null;
        })
        .find(r => r);
};

export const updateBrowserHistory = (url, method = 'pushState') => {
    try {
        window.history[method](null, null, url);
    } catch(e) {console.warn(e);}
};