import {isString} from '../../../utils/data';
import {queryFromParams} from './url';

export const pathFromUrl = (url = window.location.hash) => {
    if(!/#/.test(url)) return '';
    return url
        .replace(/^.*#\//, '')
        .replace(/\?.*$/, '')
        .replace(/\/$/, '');
};

export const pathFromNavigation = (to = '', from = '') => {
    const tDirs = to.split('../');
    if(tDirs.length <= 1) return to || from;
    let fDirs = from.split('/');
    tDirs.forEach(dir => {
        if(dir !== '') return fDirs.push(dir);
        const lfDir = fDirs[fDirs.length - 1];
        if(['create', 'edit', 'show', 'list'].indexOf(lfDir) !== -1 || /{.*}/.test(lfDir)) fDirs.pop();
    });
    return pathFromDirectories(...fDirs);
};

export const pathFromDirectories = (...directories) => {
    return [...directories].filter(dir => dir).map(dir => (dir+'').replace(/(?:^\/*)(.*)(?:\b\/*$)/, '$1')).join('/');
};

export const pathMatch = (pathA, pathB, strict = true) => {
    if(!isString(pathA) || !isString(pathB)) return false;
    const pA = pathA.split('/');
    const pB = pathB.split('/');
    if(strict && pA.length !== pB.length) return false;
    return pA.every((a, index) => {
        if(a.match(/({.*})/) !== null) return true;
        return a === pB[index];
    });
};

export const pathFromLocation = ({path, params}) => {
    let newParams = {...params};
    if(!path) return '' + queryFromParams(newParams);
    const newPathSegments = path.split('/').map(segment => {
        const match = segment.match(/(?:{)(.*)(?:})/);
        if(Array.isArray(match) && match.length >= 2) {
            const param = newParams[match[1]];
            delete newParams[match[1]];
            return param;
        }
        return segment;
    });
    return pathFromDirectories(...newPathSegments) + queryFromParams(JSON.parse(JSON.stringify(newParams)));
};