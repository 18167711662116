import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Cell.scss';

const Cell = props => {
    const {children, size = 'auto'} = props;
    return (
        <div {...applyClassName(['Cell', size], props)}>
            {children}
        </div>
    );
};

export default Cell;

export const Full = props => <Cell {...props} size={'full'}/>;
export const Auto = props => <Cell {...props} size={'auto'}/>;
export const Shrink = props => <Cell {...props} size={'shrink'}/>;
export const Grow = props => <Cell {...props} size={'grow'}/>;

export const Block = props => {
    const {children, size = 'auto', orientation = 'vertical'} = props;
    return (
        <Cell size={size} {...applyClassName('container', props)}>
            <Cell {...applyClassName(['block', orientation])}>
                {children}
            </Cell>
        </Cell>
    );
};

export const Centered = props => {
    const {children, size = 'auto', vertical = true, horizontal = true} = props;
    return (
        <Cell size={size} {...applyClassName(['centered', (vertical && 'vertical'), (horizontal && 'horizontal')], props)}>
            {children}
        </Cell>
    );
};