import React from 'react';
import InputGroup from '../../../../components/Form/InputGroup';
import WeekSelect from './WeekSelect';
import YearSelect from './YearSelect';
import Grid from '../../../../components/Grid/Grid';
import {Auto, Shrink} from '../../../../components/Grid/Cell';
import {useDate} from '../../../../components/Date/DateProvider';
import Button from '../../../../components/Button/Button';

import './DateSelect.scss';

const DateSelect = props => {
    return (
        <Grid className={'DateSelect'}>
            <Auto>
                <InputGroup field={<WeekSelect placeholder={'Kalenderwoche'}/>}/>
            </Auto>
            <Auto>
                <InputGroup field={<YearSelect placeholder={'Jahr'}/>}/>
            </Auto>
            <Shrink>
                <TodayButton/>
            </Shrink>
        </Grid>
    );
};

export default DateSelect;

const TodayButton = props => {
    const [, dateDispatch] = useDate();
    return <Button onClick={() => dateDispatch({type: 'setDate', payload: new Date()})}>heute</Button>;
};