import React from 'react';

import {weekFromParams, yearFromParams} from '../../../../utils/date';
import {bookingPath} from '../../../../config/networking';
import {useDate} from '../../../../components/Date/DateProvider';
import {useLocationParams} from '../../../../components/Router/RouterProvider';
import BookingProvider from '../../../../components/Booking/BookingProvider';

const WeekBookingProvider = props => {
    const {children} = props;
    const [date] = useDate();

    const params = useLocationParams();
    const [path, setPath] = React.useState();

    React.useEffect(() => {
        const {room, anonymous, attendees} = params;
        setPath(bookingPath('', {room, cw: weekFromParams(params, date), year: yearFromParams(params, date), anonymous, attendees}));
    }, [date, params]);

    return (
        <BookingProvider path={path}>
            {children}
        </BookingProvider>
    );
};

export default WeekBookingProvider;