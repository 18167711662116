import React from 'react';

import {fromPairs} from '../../../utils/data';
import {pathFromUrl} from './path';

export const paramsFromUrl = ({url = window.location.hash, path = ''}) => {
    return {...urlParamsFromUrl({url, path}), ...queryParamsFromUrl(url)};
};

const urlParamsFromUrl = ({url = window.location.hash, path = ''}) => {
    const up = pathFromUrl(url).split('/');
    const pairs = path
        .split('/')
        .map((rp, index) => {
            const match = rp.match(/(?:{)(.*)(?:})/);
            if(Array.isArray(match) && match.length > 1) return [match[1], up[index]];
            return null;
        })
        .filter(p => p);
    return fromPairs(pairs);
};

export const queryParamsFromUrl = (url = window.location.hash) => {
    const query = queryFromUrl(url);
    if(!query) return {};
    const pairs = query.split('&').map(entry => entry.split('='));
    return fromPairs(pairs);
};

const queryFromUrl = (url = window.location.hash) => {
    const query = url.match(/\?.*$/);
    if(query === null) return '';
    return query[0].substr(1);
};

export const useQueryParams = (url = window.location.hash) => {
    const [params, setParams] = React.useState(queryParamsFromUrl(url));

    const handleHashChange = React.useCallback(() => setParams(queryParamsFromUrl(url)), [url]);

    React.useEffect(() => {
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    });

    return params;
};
