
export const flattenDeep = arr => {
    return arr.reduce((acc, val) => Array.isArray(val) ? acc.concat(flattenDeep(val)) : acc.concat(val), []);
};

export const join = (arr, separator) => flattenDeep(arr).filter(n => n).join(separator);

export const fromPairs = pairs => {
    let obj = {};
    pairs.forEach(pair => obj[pair[0]] = pair[1]);
    return obj;
};

export const isNil = value => value === undefined || value === null || value === '';

export const isEmpty = value => isNil(value) || (Array.isArray(value) && value.length <= 0) || Object.keys(value).length <= 0;

export const isTrue = value =>  value && (value === true || value === 'true' || value === 1 || value === '1');

export const isString = value => typeof value === 'string';

export const flatten = arr => [].concat(...arr);

export const union = (...args) => [...new Set(flatten(args))];

export const times = (count, callback) => Array.from({length: count}).map((value, index) => callback(index));

export const random = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};