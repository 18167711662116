import React from "react";
import {applyClassName} from "./properties";

export const getDisplayName = component => {
    if(typeof component === 'string' && component.length > 0) return component;
    const {displayName, name, type = {}} = component || {};
    return displayName || type.displayName || name || type.name;
};

export const createElement = (component, props) => {
    if(React.isValidElement(component)) return component;
    return React.createElement(component, props);
};

export const cloneElement = (component, props) => {
    if(!React.isValidElement(component)) return component;
    const {className, ...restProps} = props;
    return React.cloneElement(component, {...restProps, ...applyClassName(className, component.props)});
};

export const cloneElements = (components, props) => {
    return React.Children.map(components, component => cloneElement(component, props), null);
};