import React from 'react';

import DateRange from '../../../../components/Date/DateRange';
import Entry from '../../../../components/Entry/Entry';

const Meeting = props => {
    const {starttime, endtime, title, attendees} = props;
    return (
        <Entry>
            <h5 className={'color-primary'}>
                <DateRange start={[starttime, 'HH:mm']} end={[endtime, 'HH:mm']} separator={<>&nbsp;- </>} />
            </h5>
            <h6>{title}</h6>
            <Attendees attendees={attendees}/>
        </Entry>
    );
};

export default Meeting;

const Attendees = props => {
    const {attendees} = props;
    if(!Array.isArray(attendees) || attendees.length <= 0) return null;
    return (
        <>
            <h6><small>Teilnehmer</small></h6>
            <ul className={'color-secondary'}>
                {attendees.map((attendee, index) => <li key={index}>{attendee}</li>)}
            </ul>
        </>
    );
};