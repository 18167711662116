import React from 'react';

import {bookingPath} from '../../../../config/networking';
import {useLocationParams} from '../../../../components/Router/RouterProvider';
import BookingProvider from '../../../../components/Booking/BookingProvider';

const DayBookingProvider = props => {
    const {children} = props;

    const params = useLocationParams();
    const [path, setPath] = React.useState();

    React.useEffect(() => {
        const {room, anonymous, attendees} = params;
        setPath(bookingPath('', {room, anonymous, attendees}));
    }, [params]);

    return (
        <BookingProvider path={path}>
            {children}
        </BookingProvider>
    );
};

export default DayBookingProvider;