import React from 'react';
import {pathFromDirectories} from '../Router/utils/path';
import {HOST_URL} from '../../config/networking';
import {applyClassName} from '../../utils/properties';

import './Image.scss';

const Image = props => {
    const {src, remote = true, alt = '', background} = props;
    if(!src) return null;
    if(background) return <div {...applyClassName('Image', props)} style={{backgroundImage: `url(${pathFromDirectories((remote ? HOST_URL : ''), src)})`}}  />;
    return <img {...applyClassName('Image', props)} src={pathFromDirectories((remote ? HOST_URL : ''), src)} alt={alt}/>;
};

export default Image;