import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Grid.scss';

const Grid = props => {
    const {children, orientation = 'horizontal'} = props;
    return (
        <div {...applyClassName(['Grid', orientation], props)}>
            {children}
        </div>
    );
};

export default Grid;