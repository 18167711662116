import {routeFromUrl} from './route';
import {paramsFromUrl} from './params';

export const locationFromRoute = route => {
    const {path = '', params = {}} = route || {};
    return {path, params};
};

export const locationFromUrl = ({url = window.location.hash, routes = []}) => {
    const route = routeFromUrl({url, routes}) || {};
    const {path} = route;
    return {
        path,
        params: paramsFromUrl({url, path})
    };
};