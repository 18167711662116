import React from 'react';
import {apiFetch} from '../../services/networking/Api';
import {useTimer} from '../../hooks/Timer';
import {useMounted} from '../../hooks/Mounted';

const initialState = {
    name: '',
    meetings: [],
    loaded: false
};

const BookingProvider = props => {
    const {children, path} = props;
    const [state, dispatch] = React.useReducer(reducer, initialState);

    const mounted = useMounted();
    const fetcher = React.useCallback(async () => {
        if(!path) return;
        const response = await apiFetch(path)
            .catch(() => ({}));
        if(!mounted.current) return;
        const {body: {name = '', meetings = []} = {}} = response || {};
        dispatch({type: 'setName', payload: name});
        dispatch({type: 'setMeetings', payload: meetings});
        dispatch({type: 'setLoaded', payload: true});
    }, [path, mounted]);

    React.useEffect(() => {
        fetcher();
    }, [fetcher]);

    useTimer(fetcher, 30000);

    return (
        <BookingContext.Provider value={[state, dispatch]}>
            {children}
        </BookingContext.Provider>
    );
};

export default BookingProvider;

const BookingContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'setName':
            return {...state, name: action.payload};
        case 'setMeetings':
            return {...state, meetings: action.payload};
        case 'setLoaded':
            return {...state, loaded: action.payload};
        default:
            return state;
    }
};

export const useBooking = () => React.useContext(BookingContext);