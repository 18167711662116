import React from 'react';
import Entry from '../../../../components/Entry/Entry';
import DateRange from '../../../../components/Date/DateRange';
import Table from '../../../../components/Table/Table';
import {applyClassName} from '../../../../utils/properties';

const List = props => {
    const {meetings} = props;
    const [payload, setPayload] = React.useState();
    React.useEffect(() => {
        const p = meetings.map(meeting => {
            return {
                data: <Meeting {...meeting}/>,
                active: meeting.current
            };
        });
        setPayload(p);
    }, [meetings]);
    return <Table {...applyClassName('', props)} orientation={'vertical'} payload={payload}/>;
};

export default List;

const Meeting = props => {
    const {starttime, endtime, title} = props;
    return (
        <Entry>
            <h4 className={'color-primary'}>{title}</h4>
            <h5><DateRange start={[starttime, 'HH:mm']} end={[endtime, 'HH:mm']} separator={<>&nbsp;- </>} /></h5>
        </Entry>
    );
};