import React from 'react';
import {applyClassName} from '../../utils/properties';

import './Footer.scss';

const Footer = props => {
    const {children} = props;
    return (
        <footer {...applyClassName('Footer', props)}>
            {children}
        </footer>
    );
};

export default Footer;