import React from 'react';
import {applyClassName} from '../../utils/properties';

import './ButtonGroup.scss';

const ButtonGroup = props => {
    const {children} = props;
    return (
        <div {...applyClassName('ButtonGroup', props)}>
            {children}
        </div>
    )
};

export default ButtonGroup;