import React from 'react';
import {applyClassName} from '../../utils/properties';

import './TableRow.scss';

const TableRow = props => {
    const {children} = props;
    return <tr {...applyClassName('TableRow', props)}>{children}</tr>;
};

export default TableRow;