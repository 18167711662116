import React from 'react';
import {applyClassName} from '../../utils/properties';

import './TableCell.scss';

const TableCell = props => {
    const {children, type = 'data'} = props;
    const Tag = type === 'head' ? 'th' : 'td';
    return <Tag {...applyClassName(['TableCell', type], props)}>{children}</Tag>;
};

export default TableCell;