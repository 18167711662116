import React from 'react';
import Timetable from './components/Booking/Timetable';

import Navigation from './components/Navigation';
import Title from './components/Title';
import BookingPage from '../../components/Booking/BookingPage';
import DateProvider from '../../components/Date/DateProvider';
import WeekBookingProvider from './components/Booking/WeekBookingProvider';

const Week = props => {
    return (
        <DateProvider>
            <WeekBookingProvider>
                <BookingPage
                    header={<Title/>}
                    body={<Timetable/>}
                    footer={<Navigation/>}
                />
            </WeekBookingProvider>
        </DateProvider>
    );
};

export default Week;