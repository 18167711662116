import React from 'react';
import {applyClassName} from '../../utils/properties';
import Grid from './Grid';

import './GridFrame.scss';

const GridFrame = props => {
    const {children, orientation = 'vertical', level = 'root'} = props;
    return (
        <Grid orientation={orientation} {...applyClassName(['GridFrame', level], props)}>
            {children}
        </Grid>
    );
};

export default GridFrame;