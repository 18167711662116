import React from 'react';

import Title from './components/Title';
import View from './components/Booking/View';
import BookingPage from '../../components/Booking/BookingPage';
import DayBookingProvider from './components/Booking/DayBookingProvider';

const Day = props => {
    return (
        <DayBookingProvider>
            <BookingPage
                header={<Title/>}
                body={<View/>}
            />
        </DayBookingProvider>
    );
};

export default Day;
