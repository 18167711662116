import {de} from "date-fns/locale";

import toDate from 'date-fns/toDate';

import isDate from 'date-fns/isDate';
import parseISO from 'date-fns/parseISO';
import parse from 'date-fns/parse';

import {isEmpty, isString} from './data';
import format from "date-fns/format";
import isValid from "date-fns/isValid";
import getISOWeek from "date-fns/getISOWeek";
import getISOWeekYear from "date-fns/getISOWeekYear";
import isSameISOWeek from "date-fns/isSameISOWeek";
import isSameISOWeekYear from "date-fns/isSameISOWeekYear";
import endOfISOWeek from "date-fns/endOfISOWeek";
import eachDayOfInterval from "date-fns/eachDayOfInterval";
import startOfISOWeek from "date-fns/startOfISOWeek";

import setISOWeek from 'date-fns/setISOWeek';
import setISOWeekYear from 'date-fns/setISOWeekYear';

export const parseDate = (...args) => {
    const [date] = args;
    if(isDate(date)) return date;
    if(isFinite(date)) return toDate(date);
    if(isString(date)) return parseISO(date);
    try {
        return parse(...args);
    } catch(e) {
        return null;
    }
};

export const formatDate = (dateLike, dateFormat, locale = de) => {
    const date = parseDate(dateLike);
    return isValid(date) ? format(date, dateFormat, {locale}) : null;
};

export const dateFromParams = (params = {}, prevDate = new Date()) => {
    const date = setISOWeekYear(setISOWeek(prevDate, (params.week || getISOWeek(prevDate))), (params.year || getISOWeekYear(prevDate)));
    return dateChanged(date, prevDate) ? date : prevDate;
};

export const paramsFromDate = (date, params = {}) => ({...params, week: getISOWeek(date), year: getISOWeekYear(date)});

export const weekFromParams = (params, prevDate = new Date()) => (!isEmpty(params) && params.week) ? (Number(params.week)) : getISOWeek(prevDate);
export const yearFromParams = (params, prevDate = new Date()) => (!isEmpty(params) && params.year) ? (Number(params.year)) : getISOWeekYear(prevDate);

export const getWeekDays = dateLike => {
    const date = parseDate(dateLike);
    if(!isValid(date)) return [];
    return eachDayOfInterval({start: startOfISOWeek(date), end: endOfISOWeek(date)});
};

export const dateChanged = (date, prevDate) => !(isSameISOWeek(date, prevDate) && isSameISOWeekYear(date, prevDate));

export const weekChanged = (week, prevWeek) => numberChanged(week, prevWeek);
export const yearChanged = (year, prevYear) => numberChanged(year, prevYear);

const numberChanged = (num, prevNum) => {
    const w = Number(num);
    const p = Number(prevNum);
    if(isNaN(w) && isNaN(p)) return false;
    return w !== p;
};