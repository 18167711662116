import React from 'react';

import {routeFromPath, routeFromUrl} from './utils/route';
import {locationFromRoute, locationFromUrl} from './utils/location';
import {pathFromNavigation} from './utils/path';
import {isString} from '../../utils/data';

const RouterProvider = props => {
    const {children, config = []} = props;
    const r = React.useReducer(reducer, {
        routes: config,
        route: routeFromUrl({routes: config}),
        location: locationFromUrl({routes: config})
    });
    return (
        <RouterContext.Provider value={r}>
            {children}
        </RouterContext.Provider>
    )
};

export default RouterProvider;

const RouterContext = React.createContext();

const reducer = (state, action) => {
    switch (action.type) {
        case 'go':
            return resolveGo(state, action);
        default:
            return state;
    }
};

const resolveGo = (state, action) => {
    const route = goRoute(state, action);
    const location = locationFromRoute(route);
    return {...state, ...{route, location}};
};

const goRoute = (state, action) => {
    const {payload = {}} = action;
    const {routes, location = {}} = state;
    const path = pathFromNavigation(payload.to, location.path);
    const params = payload.options.merge ? {...location.params, ...payload.params} : payload.params;
    return routeFromPath({path, params, routes});
};

export const useRouter = () => {
    const [, dispatch] = React.useContext(RouterContext);
    const go = React.useCallback(
        (to = '', params = {}, options = {}) => dispatch({type: 'go', payload: {to, params, options}}),
        [dispatch]
    );
    return {go};
};

const useState = key => {
    const [router] = React.useContext(RouterContext);
    return isString(key) ? router[key] : router;
};

export const useLocation = () => useState('location');
export const useRoutes = () => useState('routes');
export const useRoute = () => useState('route');

export const useLocationParams = key => {
    const [router] = React.useContext(RouterContext);
    const {params = {}} = router.location || {};
    return isString(key) ? params[key] : params;
};