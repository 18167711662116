import React from 'react';
import Select from '../../../../components/Form/Field/Select';
import {useDate} from '../../../../components/Date/DateProvider';
import getISOWeek from "date-fns/getISOWeek";
import getISOWeeksInYear from "date-fns/getISOWeeksInYear";
import isValid from "date-fns/isValid";
import {times} from '../../../../utils/data';

const WeekSelect = props => {

    const [date, dateDispatch] = useDate();

    const [value, setValue] = React.useState('');
    const [options, setOptions] = React.useState([]);

    React.useEffect(() => {
        if(!isValid(date)) return;
        const options = times(getISOWeeksInYear(date), i => ({value: i+1, name: i+1}));
        setValue(getISOWeek(date));
        setOptions(options);
    }, [date]);

    const handleChange = React.useCallback(event => {
        dateDispatch({type: 'setWeek', payload: event.target.value});
    }, [dateDispatch]);

    return (
        <Select
            {...props}
            options={options}
            value={value}
            onChange={handleChange}
        />
    );
};

export default WeekSelect;