import React from 'react';
import {
    useRouter, useLocation, useRoutes
} from './RouterProvider';
import {updateBrowserHistory} from './utils/router';
import {urlFromLocation} from './utils/url';
import {locationFromUrl} from './utils/location';

const HashRouter = props => {
    const {children} = props;

    const router = useRouter();
    const routes = useRoutes();
    const location = useLocation();

    React.useEffect(() => {
        updateBrowserHistory(urlFromLocation(location), 'pushState');
    }, [location]);

    React.useEffect(() => {
        window.addEventListener('hashchange', handleHashChange);
        return () => window.removeEventListener('hashchange', handleHashChange);
    });

    const handleHashChange = (event) => {
        const {path, params} = locationFromUrl({url: event.newURL, routes});
        router.go(path, params);
    };

    return <React.Fragment>{children}</React.Fragment>;
};

export default HashRouter;