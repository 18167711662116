import React from 'react';

import {formatDate} from '../../utils/date';

const DateRange = props => {
    const {start, end, separator, locale} = props;
    const [startDate, startFormat] = start;
    const [endDate, endFormat] = end;
    return (
        <>
            {formatDate(startDate, startFormat, locale)}
            {separator ? separator : <>&nbsp;-&nbsp;</>}
            {formatDate(endDate, endFormat, locale)}
        </>
    );
};

export default DateRange;