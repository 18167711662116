import React from 'react';
import GuestsPage from '../../components/Guests/GuestsPage';
import Welcome from './components/Welcome';
import GuestsProvider from '../../components/Guests/GuestsProvider';

const Guests = props => {
    return (
        <GuestsProvider>
            <GuestsPage
                body={<Welcome/>}
            />
        </GuestsProvider>
    );
};

export default Guests