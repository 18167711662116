import React from 'react';
import {applyClassName} from '../../utils/properties';
import GridFrame from '../Grid/GridFrame';
import {Block, Centered, Grow, Shrink} from '../Grid/Cell';
import Grid from '../Grid/Grid';
import Logo from '../Logo/Logo';
import Footer from '../Page/Footer';

const GuestsPage = props => {
    const {body} = props;
    return (
        <GridFrame {...applyClassName('Page', props)}>
            <Block>
                {body}
            </Block>
            <Shrink>
                <Footer>
                    <Grid>
                        <Grow>
                            <Centered>
                                <Logo/>
                            </Centered>
                        </Grow>
                    </Grid>
                </Footer>
            </Shrink>
        </GridFrame>
    );
};

export default GuestsPage;