import React from 'react';
import {applyClassName} from '../../utils/properties';
import GridFrame from '../Grid/GridFrame';
import {Block, Centered, Grow, Shrink} from '../Grid/Cell';
import Header from '../Page/Header';
import Grid from '../Grid/Grid';
import Logo from '../Logo/Logo';
import Footer from '../Page/Footer';
import {useBooking} from './BookingProvider';

const BookingPage = props => {
    const {header, body, footer} = props;
    const [booking] = useBooking();
    const {name} = booking || {};
    return (
        <GridFrame {...applyClassName('Page', props)}>
            <Shrink>
                <Header>
                    <Grid>
                        <Grow>
                            {header}
                        </Grow>
                        <Shrink>
                            <h4>
                                {name}
                            </h4>
                        </Shrink>
                    </Grid>
                </Header>
            </Shrink>
            <Block>{body}</Block>
            <Shrink>
                <Footer>
                    <Grid>
                        <Grow>
                            <Centered horizontal={false}>
                                <Logo/>
                            </Centered>
                        </Grow>
                        {footer}
                    </Grid>
                </Footer>
            </Shrink>
        </GridFrame>
    );
};

export default BookingPage;