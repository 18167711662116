import Week from '../views/Week/Week';
import Day from '../views/Day/Day';
import Guests from '../views/Guests/Guests';

import {pathFromDirectories} from '../components/Router/utils/path';

export const roomsConfig = {
    path: 'rooms/{room}',
    initialPath: 'day',
    routes: [
        {
            isDefault: true,
            path: 'day',
            component: Day
        },
        {
            path: 'week',
            component: Week
        },
        {
            path: 'guests',
            component: Guests
        }
    ]
};

const routeFromConfig = (config, parentRoute) => {
    const {initialPath, component, isDefault} = config;
    const path = pathFromDirectories(parentRoute.path, config.path);
    let route = {
        path, component, isDefault, params: {},
        initialPath: initialPath ? pathFromDirectories(path, initialPath) : ''
    };
    route.routes = routesFromConfig(config.routes || [], route);
    return route;
};

export const routesFromConfig = (configs, parentRoute) => {
    return configs.map(config => routeFromConfig(config, parentRoute));
};

export const routesConfig = routesFromConfig([
    roomsConfig
], {});