import React from 'react';
import {formatDate} from '../../../utils/date';
import {useTimer} from '../../../hooks/Timer';

const Title = props => {
    const [date, setDate] = React.useState(new Date());
    useTimer(() => {
        setDate(new Date());
    }, 15000);
    return (
        <h4 className={'color-secondary'}>
            {formatDate(date, 'do MMMM yyyy, p')} Uhr
        </h4>
    );
};

export default Title;