import React from 'react';

const Select = props => {
    const {options, placeholder, children, ...restProps} = props;
    return (
        <select {...restProps}>
            {children}
            <SelectOptions options={options} placeholder={placeholder}/>
        </select>
    );
};

export default Select;

const SelectOptions = props => {
    const {options = [], placeholder = ''} = props;
    // const options = Array.isArray(collection) ? collection : [];
    // console.log("SelectOptions", options, placeholder);
    return (
        <>
            {placeholder && options.length > 1 && <option className={'placeholder'} value={''}>{placeholder}</option>}
            {options.map(item => {
                const {value, name} = item || {};
                return <option key={value} value={value}>{name}</option>;
            })}
        </>
    );
};